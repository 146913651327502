body {
  margin: 0px;
  height: 100vh;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.myTeamBox {
  height: 60vh;
  overflow-y: scroll;
  cursor: pointer;
}

.playerHitsRow {
  border-bottom: 1px solid #ebeced2c;
  text-align: left;
  margin: 5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.playerHitsRow:hover {
  -webkit-box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 0.28);
  -moz-box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 0.28);
  box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 0.28);
  background-color: rgba(0, 118, 215, 0.123);
}

.playerHitsImage {
  margin-right: 10px;
}

.playerHitsContent {
  padding: 10px;
}

.playerHitsBox {
  height: 90vh;
  overflow-y: scroll;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}
